import React from 'react'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import Line from '../../components/Line'
import Button from '../../components/Button'
import Product from './Product'
import Stack from '../../components/Stack'
import Triangle from '../../components/Triangle'
import { MobileContainer, LaptopContainer } from '../../components/Common'

import useHomePage from '../../queries/useHomePage'
import useProductsPage from '../../queries/useProductsPage'
import { P, h3Style, H2, h4SemiBoldStyle } from '../../styleElements'
import mq from '../../utils/mediaQuery'
import purple from '../../assets/images/homepage_purple_bg.svg'
import productList from '../../constants/productList'

const INFO_TITLE = 'WE HELP PEOPLE CREATE & CELEBRATE'
const INFO_BODY = `
  At PicCollage Company, innovation and creativity are at the heart of everything we do -
  we pride ourselves on embracing the concept of
  <a href="https://piccollage.notion.site/Creative-AI-2806350a72824eb4a320a9632533a838?pvs=4" target="_blank">Creative AI</a>.
  At its core, <a href="https://piccollage.notion.site/Creative-AI-2806350a72824eb4a320a9632533a838?pvs=4" target="_blank">Creative AI</a>
  is about harnessing the power of AI to enable anyone to reach their full potential.
`
const INFO_BODY_2 = `
  Millions of people around the world use <a href="https://piccollage.com" target="_blank" rel="noreferrer noopener">PicCollage</a>
  and our apps to create, celebrate and connect with each other. Through our products,
  we have inspired countless users to create beautiful collages, slick video montages,
  incredible memes and so much more…
`

const INFO_BUTTON = 'View Our Products'
const CAREERS_TITLE = 'JOIN US TO BUILD, LEARN AND GROW'
const CAREERS_BODY = `
  We are a group of dreamers and builders who have come together to build magical and 
  meaningful products for people around the world. 
  In a fun, creative and international environment, 
  our team members learn together and grow together. 
  If you want to be a part of a small team with a big impact — let’s talk!`
const CAREERS_BUTTON = 'View Our Openings'
const PRODUCT_TITLE = 'MAKE THE WORLD FUN AND CREATIVE'
const LAPTOP_PRODUCT_TITLE = 'MAKE THE WORLD <br /> FUN AND CREATIVE'
const PRODUCT_BUTTON = 'Find Out More'

const HomePage = () => {
  const nodes = useHomePage()
  const productNodes = useProductsPage()
  const productListFormatted = [
    'PicCollage',
    'OnBeat',
    'Beam',
    'Noodle',
    'Become',
    'MemeMe',
    'Convert',
    'SnapJoy'
  ]
    .map(name =>
      productList.find(p => p.title.toLowerCase() === name.toLowerCase())
    )
    .map(({ title, icon, homePageUrl }) => {
      const name = title
      const image = productNodes[icon]
      const url = homePageUrl

      return { name, image, url }
    })

  return (
    <Layout>
      <SEO title="Home" />
      <Mobile>
        <HeroImageContaienr fluid={nodes.dotsBGImage}>
          <HeroImageWrapper>
            <Img
              fluid={nodes.heroImage}
              objectFit="cover"
              objectPosition="50% 50%"
            />
          </HeroImageWrapper>
        </HeroImageContaienr>
        <InfoWrapper>
          <InfoContent>
            <P>{INFO_TITLE}</P>
            <InfoTitleLine />
            <InfoBody dangerouslySetInnerHTML={{ __html: INFO_BODY }} />
            <InfoBody
              dangerouslySetInnerHTML={{ __html: INFO_BODY_2 }}
              marginTop="20px"
            />
          </InfoContent>
          <Button
            type="outlineDark"
            to="/products"
            icon="next_active"
            activeIcon="next_white"
          >
            {INFO_BUTTON}
          </Button>
        </InfoWrapper>
        <CareersContainer>
          <Triangle />
          <ValueImageFrame>
            <ValueImag1eWrapper>
              <Stack shadow={valueImage1Shadow}>
                <ValueImage
                  fluid={nodes.value1Image}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </Stack>
            </ValueImag1eWrapper>

            <ValueImag2eWrapper>
              <Stack shadow={valueImage2Shadow}>
                <ValueImage
                  fluid={nodes.value2Image}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </Stack>
            </ValueImag2eWrapper>
          </ValueImageFrame>

          <CareersContent>
            <CareersTitle>{CAREERS_TITLE}</CareersTitle>
            <CareersTitleLine color="white" />
            <CareersBody dangerouslySetInnerHTML={{ __html: CAREERS_BODY }} />
          </CareersContent>
          <Button
            type="outlineLight"
            to="/careers"
            icon="next_white"
            activeIcon="next_active"
          >
            {CAREERS_BUTTON}
          </Button>
        </CareersContainer>
        <ProductContainer>
          <ProductTitleLine />
          <ProductTitle>{PRODUCT_TITLE}</ProductTitle>
          <ProductContent>
            {productListFormatted.map(product => (
              <ProductItem key={product.name} {...product} />
            ))}
          </ProductContent>
          <Button
            type="outlineDark"
            to="/products"
            icon="next_active"
            activeIcon="next_white"
          >
            {PRODUCT_BUTTON}
          </Button>
        </ProductContainer>
        <ProductBGContainer fluid={nodes.productBGImageMobile} />
      </Mobile>
      <Laptop>
        <LaptopInfoArea>
          <LaptopInfoContainer>
            <LaptopDotsWrapper>
              <Img
                fluid={nodes.dotsBGImage}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </LaptopDotsWrapper>
            <LaptopInfo>
              <InfoContent>
                <P>{INFO_TITLE}</P>
                <InfoTitleLine />
                <InfoBody dangerouslySetInnerHTML={{ __html: INFO_BODY }} />
                <InfoBody
                  dangerouslySetInnerHTML={{ __html: INFO_BODY_2 }}
                  marginTop="20px"
                />
              </InfoContent>
              <Button
                type="outlineDark"
                to="/products"
                icon="next_active"
                activeIcon="next_white"
              >
                {INFO_BUTTON}
              </Button>
            </LaptopInfo>
            <HeroImageWrapper>
              <Img
                fluid={nodes.heroImage}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </HeroImageWrapper>
          </LaptopInfoContainer>
          <PurpleOutline src={purple} />
        </LaptopInfoArea>
        <LaptopCareersArea>
          <LaptopCareersContainer>
            <LaptopCareersImageWrapper>
              <ValueImag1eWrapper>
                <Stack shadow={valueImage1Shadow}>
                  <ValueImage
                    fluid={nodes.value1Image}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  />
                </Stack>
              </ValueImag1eWrapper>

              <ValueImag2eWrapper>
                <Stack shadow={valueImage2Shadow}>
                  <ValueImage
                    fluid={nodes.value2Image}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  />
                </Stack>
              </ValueImag2eWrapper>
            </LaptopCareersImageWrapper>
            <LaptopCareersDesc>
              <CareersContent>
                <CareersTitle>{CAREERS_TITLE}</CareersTitle>
                <CareersTitleLine color="white" />
                <CareersBody
                  dangerouslySetInnerHTML={{ __html: CAREERS_BODY }}
                />
              </CareersContent>
              <LaptopCareersButton>
                <Button
                  type="outlineLight"
                  to="/careers"
                  icon="next_white"
                  activeIcon="next_active"
                >
                  {CAREERS_BUTTON}
                </Button>
              </LaptopCareersButton>
            </LaptopCareersDesc>
          </LaptopCareersContainer>
        </LaptopCareersArea>
        <LaptopProductArea>
          <LaptopProductContainer>
            <LaptopProductFrame>
              <ProductTitleLine />
              <ProductTitle
                dangerouslySetInnerHTML={{ __html: LAPTOP_PRODUCT_TITLE }}
              />
              <ProductContent>
                {productListFormatted.map(product => (
                  <ProductItem key={product.name} {...product} />
                ))}
              </ProductContent>
              <Button
                type="outlineDark"
                to="/products"
                icon="next_active"
                activeIcon="next_white"
              >
                {PRODUCT_BUTTON}
              </Button>
            </LaptopProductFrame>
            <LaptopProductCaseImageWrapper>
              <ProductBGContainer fluid={nodes.productBGImage} />
            </LaptopProductCaseImageWrapper>
          </LaptopProductContainer>
        </LaptopProductArea>
      </Laptop>
    </Layout>
  )
}

const Mobile = styled.div`
  display: block;

  ${mq.md} {
    display: none;
  }
`

const Laptop = styled.div`
  display: none;

  ${mq.md} {
    display: block;
    background-color: ${props => props.theme.color.gray4};
  }
`

const HeroImageContaienr = styled(BackgroundImage)`
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 500px 500px;
  background-position: -200px -100px;
`

const HeroImageWrapper = styled.div`
  width: 78%;

  ${mq.md} {
    max-width: 380px;
  }
`

const InfoWrapper = styled(MobileContainer)`
  background-color: ${props => props.theme.color.gray4};
  padding-top: 40px;
  padding-bottom: 100px;
`

const InfoContent = styled.div`
  padding-top: 0;
  padding-bottom: 40px;

  ${mq.md} {
    padding-top: 0px;
    padding-bottom: 20px;
    max-width: 500px;
  }
`

const InfoTitleLine = styled(Line)`
  margin: 10px 0;
  width: 100%;
`

const InfoBody = styled.p`
  ${h4SemiBoldStyle};
  margin-top: ${props => props.marginTop || '0'};
`

const CareersContainer = styled(MobileContainer)`
  background-color: ${props => props.theme.color.primary1};
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
`

const CareersContent = styled.div`
  padding-bottom: 40px;
`

const CareersTitle = styled(P)`
  color: ${props => props.theme.color.white};

  ${mq.md} {
    padding-left: 36%;
  }
`

const CareersTitleLine = styled(Line)`
  margin: 10px 0;
  width: 100%;
`

const CareersBody = styled.p`
  ${h3Style};
  color: ${props => props.theme.color.white};

  ${mq.md} {
    padding-left: 24%;
  }
  ${mq.lg} {
    padding-left: 36%;
  }
`

const ProductContainer = styled(MobileContainer)`
  background-color: ${props => props.theme.color.gray4};
  padding-top: 40px;
`

const ProductTitleLine = styled(Line)`
  margin-bottom: 18px;
  width: 100%;
`

const ProductTitle = styled(H2)``

const ProductContent = styled.div`
  padding-top: 40px;
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${mq.md} {
    justify-content: flex-start;
    /* flex-wrap: nowrap; */
  }
`

const ProductItem = styled(Product)`
  width: 45%;
  margin-bottom: 30px;

  ${mq.md} {
    /* width: 120px; */
    /* margin-right: 20px; */
    width: 25%;
  }
`

const ProductBGContainer = styled(Img)`
  width: 100%;
  margin-top: 20px;
  background-color: ${props => props.theme.color.gray4};

  ${mq.md} {
    margin-top: 0;
  }
`

const ValueImageFrame = styled.div`
  position: relative;
  width: 100%;
  height: calc((100vw - 20px - 20px) * 0.75);
`

const ValueImag1eWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 82%;

  ${mq.md} {
    width: 107%;
    top: -100px;
    left: -100px;
  }
`

const valueImage1Shadow = props => css`
  background-color: ${props.theme.color.primary2};
  bottom: -20px;
  right: -20px;

  ${mq.md} {
    bottom: -50px;
    right: -50px;
  }
`

const ValueImag2eWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 54%;
  z-index: 1;

  ${mq.md} {
    width: 70%;
    bottom: -50px;
    right: -50px;
  }
`

const valueImage2Shadow = props => css`
  background-color: ${props.theme.color.primary3};
  top: -20px;
  left: -20px;

  ${mq.md} {
    top: -30px;
    left: -30px;
  }
`

const ValueImage = styled(Img)`
  width: 100%;
  z-index: 1;
`

const LaptopInfoArea = styled.div`
  padding-top: 30px;
  padding-bottom: 10px;
  position: relative;
`

const LaptopInfoContainer = styled(LaptopContainer)`
  display: flex;
  padding-left: 80px;
  padding-bottom: 20px;
  position: relative;
`

const LaptopInfo = styled.div`
  flex-grow: 1;
`

const LaptopDotsWrapper = styled.div`
  width: 800px;
  position: absolute;
  transform: translate(-48%, -46%);
  pointer-events: none;
`

const LaptopCareersArea = styled.div`
  padding-top: 200px;
  padding-bottom: 120px;
  background-color: ${props => props.theme.color.primary1};
  position: relative;
`

const LaptopCareersContainer = styled(LaptopContainer)`
  display: flex;
`

const LaptopCareersDesc = styled.div`
  width: 60%;

  ${mq.lg} {
    width: 50%;
  }
`

const LaptopCareersImageWrapper = styled.div`
  width: 50%;
  position: relative;
`

const LaptopCareersButton = styled.div`
  padding-left: 24%;

  ${mq.lg} {
    padding-left: 36%;
  }
`

const LaptopProductArea = styled.div`
  padding-top: 70px;
  padding-bottom: 80px;
  overflow: hidden;
`

const LaptopProductContainer = styled(LaptopContainer)`
  display: flex;
  position: relative;
`

const LaptopProductFrame = styled.div`
  width: 70%;
  position: relative;
  z-index: 1;
`

const LaptopProductCaseImageWrapper = styled.div`
  position: absolute;
  right: -1200px;
  top: -72px;
  width: 1680px;
`

const PurpleOutline = styled.img`
  width: 100%;
  position: absolute;
  bottom: -1px;
`

export default HomePage
